<template>
  <div>
    <b-alert
      v-if="activeIncident"
      show
      fade
      class="p-1"
      variant="warning"
    >
      {{ $t("AppHelpDesk.activeIncident") }}
    </b-alert>
    <b-card>
      <b-card-header>
        <h2>
          <feather-icon icon="LayersIcon" />
          {{ $t("AppHelpDesk.requestTicket") }}
        </h2>
      </b-card-header>
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AppUser.list.rolAuthor')"
                  rules="required"
                >
                  <b-form-group>
                    <h4>{{ $t("AppUser.list.rolAuthor") }}</h4>
                    <v-select
                      v-model="ticketData.nombreUsuarioReporta"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      label="texto"
                      :options="users"
                      :reduce="(option) => option.textoAlterno"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AppHelpDesk.columns.category')"
                  rules="required"
                >
                  <b-form-group>
                    <h4>{{ $t("AppHelpDesk.columns.category") }}</h4>
                    <v-select
                      v-model="ticketData.ticketCategoriaId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      label="texto"
                      :options="categories"
                      :reduce="(category) => category.id"
                      @input="getCategory"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="$t('AppHelpDesk.categoryDescription')"
                  label-for="description"
                >
                  <b-form-textarea
                    v-model="categorySelected.descripcion"
                    rows="3"
                    disabled
                    :placeholder="$t('AppHelpDesk.placeHolder')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="isSoftware">
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AppHelpDesk.ipComputer')"
                  rules="required|ipAddress"
                >
                  <b-form-group>
                    <h4>
                      {{ $t("AppHelpDesk.ipComputer") }}
                      <feather-icon
                        v-b-tooltip="'Tooltip!'"
                        icon="InfoIcon"
                        variant="primary"
                      />
                    </h4>
                    <b-form-input
                      v-model="ticketData.ipEquipo"
                      type="text"
                      placeholder="10.0.3.0"
                      variant="primary"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AppHelpDesk.problemDescription')"
                  rules="required"
                >
                  <b-form-group>
                    <h4>{{ $t("AppHelpDesk.problemDescription") }}</h4>
                    <b-form-textarea
                      v-model="ticketData.descripcion"
                      rows="3"
                      :placeholder="$t('AppHelpDesk.placeHolder')"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <h4>{{ $t("AppHelpDesk.evidence") }}</h4>
                <file-uploader
                  v-model="ticketData.archivoSeleccionado"
                  :file-name.sync="ticketData.archivoSeleccionadoNombre"
                  @on-upload-file="onAddFile"
                />
              </b-col>
              <b-col cols="12">
                <b-table
                  :items="ticketData.evidenciaArchivos"
                  :fields="filesTableFields"
                >
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item @click="onDeleteFile(data.item.guid)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">{{
                          $t("Lists.Delete")
                        }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
                <b-alert
                  v-if="ticketData.evidenciaArchivos && ticketData.evidenciaArchivos.length === 0"
                  show
                  fade
                  class="p-1"
                  variant="warning"
                >
                  {{ $t("AppHelpDesk.recommendedEvidence") }}
                </b-alert>
              </b-col>
            </b-row>
            <b-row class="float-right mt-2 mb-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="addingTicket"
                  small
                  class="mr-1"
                />
                {{ $t("Lists.Add") }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  VBTooltip,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
  BTable,
  BDropdown,
  BDropdownItem,
  BAlert,
  //   BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FileUploader from '@/components/FileUploader.vue'
import i18n from '@/libs/i18n'
import formValidation from '@core/comp-functions/forms/form-validation'
import optionService from '@/services/option.service'
import helpDeskService from '@/services/helpDesk.service'
import { ref, computed } from '@vue/composition-api'
import router from '@/router'
import common from '@/libs/common'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormTextarea,
    BFormInput,
    BButton,
    BSpinner,
    BTable,
    BDropdown,
    BDropdownItem,
    BAlert,
    BFormGroup,
    BFormInvalidFeedback,

    vSelect,

    ValidationObserver,
    ValidationProvider,

    FileUploader,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const {
      fetchOptions,
    } = optionService()

    const {
      createTicket,
      fetchCategoria,
      fecthActiveIncident,
    } = helpDeskService()

    const activeIncident = ref(false)
    const categories = ref([])
    const categorySelected = ref({
      ticketCategoriaId: '',
      nombre: '',
      descripcion: '',
    })
    const addingTicket = ref(false)
    const foundCategory = ref({
      id: null,
      texto: '',
      textoAlterno: 0,
    })
    fecthActiveIncident(data => {
      activeIncident.value = data
    })

    const {
      getUserName,
    } = common()

    const blankTicketData = {
      ticketId: 0,
      descripcion: '',
      ipEquipo: '',
      nombreUsuarioReporta: getUserName(),
      ticketCategoriaId: null,
      archivoSeleccionado: '',
      archivoSeleccionadoNombre: '',
      evidenciaArchivos: [],
    }
    const filesTableFields = [
      {
        key: 'nombre',
        label: i18n.t('classification.edit.labelArchive'),
        sortable: false,
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
      },
    ]

    const ticketData = ref(JSON.parse(JSON.stringify(blankTicketData)))
    const users = ref([])
    const resetTicketData = () => {
      ticketData.value = JSON.parse(JSON.stringify(blankTicketData))
    }
    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetTicketData)
    // const {
    //   fetchUsers,
    // } = useradminService()

    fetchOptions({ nombreDominio: 'Empleado' }, data => {
      users.value = data
    })
    fetchOptions({ nombreDominio: 'CategoriaTicket' }, data => {
      categories.value = data
    })

    const onSubmit = () => {
      addingTicket.value = true
      createTicket(ticketData.value)
        .then(() => {
          addingTicket.value = false
          router.push({ name: 'apps-tickets-list' })
        })
        .catch(() => {
          addingTicket.value = false
        })
    }
    const onAddFile = () => {
      ticketData.value.evidenciaArchivos.push(
        {
          guid: ticketData.value.archivoSeleccionado,
          nombre: ticketData.value.archivoSeleccionadoNombre,
        },
      )

      ticketData.value.archivoSeleccionado = ''
      ticketData.value.archivoSeleccionadoNombre = ''
    }
    const onDeleteFile = guid => {
      ticketData.value.evidenciaArchivos = ticketData.value.evidenciaArchivos.filter(f => f.guid !== guid)
    }
    const getCategory = () => {
      if (ticketData.value.ticketCategoriaId !== null) {
        fetchCategoria(ticketData.value.ticketCategoriaId, data => {
          categorySelected.value = data
        })
      } else {
        categorySelected.value.descripcion = ''
      }
    }

    const isSoftware = computed(() => {
      const softwareCategory = categories.value.find(category => category.texto === 'Software')
      if (softwareCategory !== null && softwareCategory !== undefined) {
        if (ticketData.value.ticketCategoriaId === softwareCategory.id) {
          return true
        }
        let isChild = false
        foundCategory.value = categories.value.find(category => category.id === ticketData.value.ticketCategoriaId)
        while (!isChild && foundCategory.value !== null && foundCategory.value !== undefined) {
          if (Number(foundCategory.value.textoAlterno) === softwareCategory.id || Number(foundCategory.value.id) === softwareCategory.id) {
            isChild = true
          }
          foundCategory.value = categories.value.find(category => category.id === Number(foundCategory.value.textoAlterno))
        }
        return isChild
      }
      return false
    })

    return {
      resetTicketData,
      onSubmit,
      onAddFile,
      onDeleteFile,
      getCategory,

      ticketData,
      users,
      categorySelected,
      categories,
      addingTicket,
      resetForm,
      refFormObserver,
      isSoftware,
      filesTableFields,
      activeIncident,
    }
  },
}
</script>
